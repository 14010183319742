import { Alert, IconButton } from "@mui/material";
import React, { FormEvent, useState } from "react";
import { validateEmail } from "../../helpers/Utils";
import CloseIcon from "@mui/icons-material/Close";
import { Snackbar } from "@mui/material";
import CompanyConnectionService from "../../services/CompanyConnectionService";
import { Link } from "react-router-dom";

const Footer = (): JSX.Element => {
  const [newsletterEmail, setNewsLetterEmail] = useState("");
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");

  const newsletterSendHandle = () => {
    if (!validateEmail(newsletterEmail)) {
      setSnackBarMessage("Email is not valid");
      setOpenSnackBar(true);
      return;
    }

    CompanyConnectionService.submitNewsletterRequest(newsletterEmail).then(
      (o) => {
        if (o === "success") {
          setSnackBarMessage("Verify Your Mail!");
          setOpenSnackBar(true);
        }
      }
    );
  };

  const handleCloseSnackBar = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackBar(false);
  };

  return (
    <>
      {/*Footer================================================== */}
      <div id="footer">
        {/*Footer Top Section */}
        <div className="footer-top-section">
          <div className="container">
            <div className="row">
              <div className="col-xl-12">
                {/*Footer Rows Container */}
                <div className="footer-rows-container">
                  {/*Left Side */}
                  <div className="footer-rows-left">
                    <div className="footer-row">
                      <div className="footer-row-inner footer-logo">
                        <img src="images/logo2.png" alt=""></img>
                      </div>
                    </div>
                  </div>

                  {/*Right Side */}
                  <div className="footer-rows-right">
                    {/*Social Icons */}
                    <div className="footer-row">
                      <div className="footer-row-inner">
                        <ul className="footer-social-links">
                          <li style={{ display: "none" }}>
                            <a
                              href="#"
                              title="Facebook"
                              data-tippy-placement="bottom"
                              data-tippy-theme="light"
                            >
                              <i className="icon-brand-facebook-f"></i>
                            </a>
                          </li>
                          <li style={{ display: "none" }}>
                            <a
                              href="#"
                              title="Twitter"
                              data-tippy-placement="bottom"
                              data-tippy-theme="light"
                            >
                              <i className="icon-brand-twitter"></i>
                            </a>
                          </li>
                          <li style={{ display: "none" }}>
                            <a
                              href="#"
                              title="Google Plus"
                              data-tippy-placement="bottom"
                              data-tippy-theme="light"
                            >
                              <i className="icon-brand-google-plus-g"></i>
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.linkedin.com/in/nearshore-biz"
                              title="LinkedIn"
                              data-tippy-placement="bottom"
                              data-tippy-theme="light"
                            >
                              <i className="icon-brand-linkedin-in"></i>
                            </a>
                          </li>
                        </ul>
                        <div className="clearfix"></div>
                      </div>
                    </div>

                    {/*Language Switcher 
                      <div className="footer-row">
                        <div className="footer-row-inner">
                          <select
                            className="selectpicker language-switcher"
                            data-selected-text-format="count"
                            data-size="5"
                          >
                            <option selected>English</option>
                            <option>Français</option>
                            <option>Español</option>
                            <option>Deutsch</option>
                          </select>
                        </div>
                      </div>*/}
                  </div>
                </div>
                {/*Footer Rows Container / End */}
              </div>
            </div>
          </div>
        </div>
        {/*Footer Top Section / End */}

        {/*Footer Copyrights */}
        <div className="footer-bottom-section">
          <div className="container">
            <div className="row">
              <div className="col-xl-12">
                © 2022 <strong>Nearshore.biz</strong> All Rights Reserved.
              </div>
            </div>
          </div>
        </div>
        {/*Footer Copyrights / End */}

        {/*Footer Middle Section */}
        <div className="footer-middle-section">
          <div className="container">
            <div className="row">
              <div className="col-xl-2 col-lg-2 col-md-3">
                <div className="footer-links" style={{ display: "none" }}>
                  <h3>For Candidates</h3>
                  <ul>
                    <li>
                      <a href="#">
                        <span>Browse Jobs</span>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <span>Add Resume</span>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <span>Job Alerts</span>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <span>My Bookmarks</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-2 col-lg-2 col-md-3">
                <div className="footer-links" style={{ display: "none" }}>
                  <h3>For Employers</h3>
                  <ul>
                    <li>
                      <a href="#">
                        <span>Browse Candidates</span>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <span>Post a Job</span>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <span>Post a Task</span>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <span>Plans & Pricing</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-2 col-lg-2 col-md-3">
                <div className="footer-links" style={{ display: "none" }}>
                  <h3>Account</h3>
                  <ul>
                    <li>
                      <a href="#">
                        <span>Log In</span>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <span>My Account</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-2 col-lg-2 col-md-3">
                <div className="footer-links">
                  <h3>Helpful Links</h3>
                  <ul>
                    <li>
                      <a
                        href="#"
                        onClick={() =>
                          (window.location.href =
                            "mailto:nearshore.biz@gmail.com")
                        }
                      >
                        <span>Contact</span>
                      </a>
                    </li>
                    <li>
                      <Link to="/privacy">
                        <span>Privacy Policy</span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-12">
                <h3>
                  <i className="icon-feather-mail"></i> Subscribe/Unsubscribe
                  Newsletter
                </h3>
                <p> Any updates related to Companies.</p>
                <div className="newsletter">
                  <input
                    type="email"
                    name="fname"
                    value={newsletterEmail}
                    onChange={(event) => {
                      setNewsLetterEmail(event.target.value);
                    }}
                    placeholder="Enter your email address"
                  ></input>

                  <button type="submit" onClick={newsletterSendHandle}>
                    <i className="icon-feather-arrow-right"></i>
                  </button>
                  <Snackbar
                    open={openSnackBar}
                    autoHideDuration={3000}
                    onClose={handleCloseSnackBar}
                  >
                    <Alert severity="warning">{snackBarMessage}</Alert>
                  </Snackbar>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*Footer Middle Section / End */}
      </div>
      {/*Footer / End */}
    </>
  );
};

export default Footer;
