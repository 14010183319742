import axios, { AxiosInstance, AxiosResponse } from "axios";
import { HttpClientRequestParameters } from "./HttpClientRequestParameters";
import { HttpStatusCode } from "./HttpStatusCode";
export class HttpClient {
  private instance: AxiosInstance | null = null;
  private baseUrl: string;
  private get http(): AxiosInstance {
    return this.instance != null ? this.instance : this.initHttp();
  }

  constructor(serverUrl: any) {
    this.baseUrl = serverUrl;
  }

  initHttp() {
    const http = axios.create();
    http.defaults.timeout = 100000;
    http.defaults.baseURL = this.baseUrl;
    http.defaults.headers.post["Content-Type"] = "application/json";
    http.defaults.headers.put["Content-Type"] = "application/json";

    http.interceptors.response.use(
      (response: AxiosResponse) => response,
      (error) => {
        const { response } = error;
        return this.handleError(response);
      }
    );
    this.instance = http;
    return http;
  }

  // @ts-ignore
  private handleError(error) {
    const { status } = error;

    switch (status) {
      case HttpStatusCode.InternalServerError:
        console.log("Internal Server Error");
        break;
      case HttpStatusCode.TooManyRequests:
        console.log("Too many requests");
        break;
      case HttpStatusCode.Unauthorized:
        console.log("Unauthorized");
        break;
      case HttpStatusCode.Forbidden:
        console.log("Forbidden");
        break;
      default:
        console.log("Another error", error);
    }

    return Promise.reject(error);
  }

  // @ts-ignore
  get<T>(parameters: HttpClientRequestParameters): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      const { url, config } = parameters;
      this.http
        .get(url, config)
        .then((response: any) => {
          resolve(response.data as T);
        })
        .catch((response) => {
          reject(response);
        });
    });
  }

  // @ts-ignore
  post<T>(parameters: HttpClientRequestParameters): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      const { url, payload, config } = parameters;
      this.http
        .post(url, payload, config)
        .then((response: any) => {
          resolve(response.data as T);
        })
        .catch((response) => {
          reject(response);
        });
    });
  }

  // @ts-ignore
  put<T>(parameters: HttpClientRequestParameters): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      const { url, payload, config } = parameters;
      this.http
        .put(url, payload, config)
        .then((response: any) => {
          resolve(response.data as T);
        })
        .catch((response) => {
          reject(response);
        });
    });
  }

  // @ts-ignore
  delete<T>(parameters: HttpClientRequestParameters): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      const { url, config } = parameters;
      this.http
        .delete(url, config)
        .then((response: any) => {
          resolve(response.data as T);
        })
        .catch((response) => {
          reject(response);
        });
    });
  }
}
