import {
  Autocomplete,
  Box,
  Chip,
  InputAdornment,
  Paper,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useState } from "react";
import backgroundImage from "../../assets/images/B2B-Networking.jpeg";
import LocationOn from "@mui/icons-material/LocationOn";
import CountryCitySearchService from "../../services/CountryCitySearchService";
import "./intro.css";
import useWindowDimensions from "../../helpers/useWindowDimensions";
import { useEffect } from "react";
import CompanySearchService from "../../services/CompanySearchService";
import { CompanyDTO } from "../../models/CompanyDTO";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
      // Default transform is "translate(14px, 20px) scale(1)""
      // This lines up the label with the initial cursor position in the input
      // after changing its padding-left.
      transform: "translate(34px, 20px) scale(1);",
    },
  },
  inputRoot: {
    color: "purple",
    // This matches the specificity of the default styles at https://github.com/mui-org/material-ui/blob/v4.11.3/packages/material-ui-lab/src/Autocomplete/Autocomplete.js#L90
    '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
      // Default left padding is 6px
      paddingLeft: 26,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderWidth: 0,
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderWidth: 0,
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderWidth: 0,
    },
    "& .MuiInput-root": {
      "&:before, :after, :hover:not(.Mui-disabled):before": {
        borderBottom: 0,
      },
      "& .MuiInput-input": {
        paddingTop: 15,
        paddingRight: 22,
        paddingBottom: 15,
        paddingLeft: 22,
      },
    },
  },
  input: {
    "& > label": {
      paddingLeft: "1.5em !important",
    },
    "& input": {
      paddingLeft: "24px !important",
    },
    "&::placeholder": {
      color: "black !important",
    },
  },
}));

interface IntroProps {
  searchResults: (
    results: CompanyDTO[],
    techs: string[],
    location: string
  ) => void;
}

const Intro = (props: IntroProps): JSX.Element => {
  const { searchResults } = props;
  const [filteredCountryCityList, setFilteredCountryCityList] = useState<
    string[]
  >([]);
  const [countryCityInput, setCountryCityInput] = useState("");
  const [availableTechs, setAvailableTechs] = useState<string[]>([]);

  const classes = useStyles();
  const { width, height } = useWindowDimensions();
  const [selectedCountryCity, setSelectedCountryCity] = useState("");
  const [selectedTechs, setSelectedTechs] = useState<any>();
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [snackBarOpen, setSnackBarOpen] = useState(false);

  useEffect(() => {
    if (availableTechs.length == 0) {
      CompanySearchService.getAllTechTags().then((res) => {
        if (res) {
          setAvailableTechs(res);
        }
      });
    }
  }, [availableTechs]);

  const onLocationChangeHandler = async (val: any) => {
    if (val == "") {
      setFilteredCountryCityList([]);
      return;
    }
    const response = await CountryCitySearchService.filterCountryCityByText(
      val
    );
    setCountryCityInput(val);

    const locations: string[] = [];
    response?.Cities?.forEach((o) => locations.push(o.name));
    response?.Countries?.forEach((o) => locations.push(o.name));
    setFilteredCountryCityList(
      locations
        .map((item) => item)
        .filter((value, index, self) => self.indexOf(value) === index)
    );
  };

  const onDeleteHandler = (selected: string) => () => {
    setSelectedTechs(selectedTechs?.filter((v: any) => v !== selected));
  };

  const searchButtonHandler = async () => {
    if (!selectedTechs || !selectedCountryCity) {
      setSnackBarMessage("Select country and expertise");
      setSnackBarOpen(true);
      return;
    }
    await CompanySearchService.getCompaniesByTech(
      selectedCountryCity,
      selectedTechs
    ).then((res) => {
      // console.log(res);
      if (!res || res?.length === 0) {
        setSnackBarMessage("There is no result!");
        setSnackBarOpen(true);
        return;
      }
      searchResults(res, selectedTechs, selectedCountryCity);
    });
  };

  const handleCloseSnackBar = () => {
    setSnackBarOpen(!snackBarOpen);
  };

  return (
    <>
      {/** Intro Banner */}
      <div className="intro-banner dark-overlay big-padding">
        {/** Transparent Header Spacer */}
        <div className="transparent-header-spacer"></div>

        <div className="container">
          {/** Intro Headline */}
          <div className="row">
            <div className="col-md-12">
              <div className="banner-headline-alt">
                <h1>Search And Hire Nearshore IT Outsourcing Companies</h1>
                <h3></h3>
                <span>
                  Money, Quality and Trust matter <br />
                  Find the best IT Consulting Service Providers in the digital
                  industry
                </span>
              </div>
            </div>
          </div>

          {/** Search Bar */}
          <div className="row">
            <div className="col-md-12">
              <div className="intro-banner-search-form margin-top-95">
                {/** Search Field */}
                <div className="intro-search-field with-autocomplete">
                  <label
                    htmlFor="autocomplete-input"
                    className="field-title ripple-effect"
                  >
                    Where?
                  </label>
                  <div className="input-with-icon">
                    {/* <input
                      id="autocomplete-input"
                      type="text"
                      placeholder="Country or City"
                    ></input>
                    <i className="icon-material-outline-location-on"></i> */}
                    <Autocomplete
                      freeSolo
                      classes={classes}
                      id="free-solo-2-demo"
                      disableClearable
                      options={filteredCountryCityList}
                      value={selectedCountryCity}
                      onChange={(event: any, newValue: string) => {
                        // console.log(newValue);
                        setSelectedCountryCity(newValue);
                      }}
                      renderOption={(props, option) => (
                        <li key={option} {...props}>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: option.replace(
                                countryCityInput,
                                "<b>" + countryCityInput + "</b>"
                              ),
                            }}
                          />
                        </li>
                      )}
                      renderInput={(params) => (
                        <div>
                          <TextField
                            {...params}
                            variant="standard"
                            placeholder="Country or City"
                            fullWidth
                            InputProps={{
                              ...params.InputProps,
                              classes: { input: classes.input },
                              style: { paddingLeft: "24px !important" },
                              disableUnderline: true,
                              startAdornment: (
                                <InputAdornment
                                  position="start"
                                  style={{ position: "absolute" }}
                                >
                                  <LocationOn />
                                </InputAdornment>
                              ),
                            }}
                            onChange={(ev) => {
                              onLocationChangeHandler(ev.target.value);
                            }}
                          />
                        </div>
                      )}
                    />
                  </div>
                </div>

                {/** Search Field */}
                <div className="intro-search-field">
                  <label
                    htmlFor="intro-keywords"
                    className="field-title ripple-effect"
                  >
                    What expertise do you want?
                  </label>
                  <div className="input-with-icon">
                    {/** <input
                    id="intro-keywords"
                    type="text"
                    placeholder="Project management or any tech stack"
                  ></input> **/}
                    <Autocomplete
                      freeSolo
                      multiple
                      classes={classes}
                      id="intro-keywords"
                      disableClearable
                      options={availableTechs}
                      onChange={(event: any, newValue) => {
                        setSelectedTechs(newValue);
                      }}
                      renderTags={(val, getTagProps) => {
                        if (width < 993) return null;
                        return selectedTechs.map(
                          (option: string, index: number) => (
                            <Chip
                              label={option}
                              {...getTagProps({ index })}
                              key={index}
                            />
                          )
                        );
                      }}
                      renderInput={(params) => (
                        <div>
                          <TextField
                            {...params}
                            variant="standard"
                            placeholder={
                              selectedTechs?.length > 0 && width > 993
                                ? ""
                                : "Project management or any tech stack"
                            }
                            fullWidth
                            InputProps={{
                              ...params.InputProps,
                              classes: { input: classes.input },

                              disableUnderline: true,
                            }}
                          />
                        </div>
                      )}
                    />
                    {width < 993 && (
                      <Box
                        mt={3}
                        sx={{
                          "& > :not(:last-child)": { mr: 1 },
                          "& > *": { mr: 1 },
                        }}
                      >
                        {selectedTechs?.map((v: any) => (
                          <Chip
                            key={v}
                            style={{ backgroundColor: "lightgray" }}
                            variant="outlined"
                            label={v}
                            onDelete={onDeleteHandler(v)}
                          />
                        ))}
                      </Box>
                    )}
                  </div>
                </div>

                {/** Button */}
                <div className="intro-search-button">
                  <button
                    className="button ripple-effect"
                    onClick={searchButtonHandler}
                  >
                    Search
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/** Stats */}
          <div className="row" style={{ display: "none" }}>
            <div className="col-md-12">
              <ul className="intro-stats margin-top-45 hide-under-992px">
                <li>
                  <strong className="counter">1,586</strong>
                  <span>Jobs Posted</span>
                </li>
                <li>
                  <strong className="counter">3,543</strong>
                  <span>Tasks Posted</span>
                </li>
                <li>
                  <strong className="counter">1,232</strong>
                  <span>Freelancers</span>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {/** Video Container */}
        <div
          className="video-container"
          style={{ backgroundImage: `url(${backgroundImage})` }}
          data-background-image="images/home-video-background-poster.jpg"
        >
          {/* <video loop autoplay muted>
              <source src="images/home-video-background.mp4" type="video/mp4">
      </video> */}
        </div>
        <Snackbar
          open={snackBarOpen}
          autoHideDuration={6000}
          onClose={handleCloseSnackBar}
          message={snackBarMessage}
        />
      </div>
    </>
  );
};

export default Intro;
