import { HttpClient } from "../interceptors/HttpClient";
import { CompanyDTO } from "../models/CompanyDTO";

const URL_PATHS = {
  GET_TECH_TAGS: `${process.env.REACT_APP_SERVER_URL}/tech/tags`,
  GET_COMPANIES_BY_TECH: `${process.env.REACT_APP_SERVER_URL}/companies/tech`,
};

const httpClient = new HttpClient(process.env.REACT_APP_SERVER_URL);
export default class CompanySearchService {
  private constructor() {
    console.log("Class can not be initialized");
  }

  static async getAllTechTags(): Promise<string[]> {
    const urlPath = `${URL_PATHS.GET_TECH_TAGS}`;
    return httpClient
      .get<string[]>({ url: urlPath })
      .then((response) => {
        return response;
      })
      .catch(async (error) => {
        throw Error(error);
      });
  }

  static async getCompaniesByTech(
    country: string,
    techs: string[]
  ): Promise<CompanyDTO[]> {
    const urlPath = `${URL_PATHS.GET_COMPANIES_BY_TECH}`;
    const payload = {
      location: country,
      techs: techs,
    };
    return httpClient
      .put<CompanyDTO[]>({ url: urlPath, payload: payload })
      .then((response) => {
        return response;
      })
      .catch(async (error) => {
        throw Error(error);
      });
  }
}
