import React, { useMemo, useState } from "react";
import { useEffect } from "react";
import { CompanyDTO } from "../../models/CompanyDTO";
import Pagination from "../pagination/Pagination";
import { currencyFormat } from "../../helpers/Utils";
import { Link } from "react-router-dom";

interface ResultProps {
  searchResults: CompanyDTO[];
  selectedTechs?: string[];
}

const Result = (props: ResultProps): JSX.Element => {
  const { searchResults, selectedTechs } = props;
  const [currentPage, setCurrentPage] = useState(1);
  const PAGE_SIZE = 10;
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [orderedResults, setOrderedResults] = useState(searchResults);

  const currentPagedResults = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PAGE_SIZE;
    const lastPageIndex = firstPageIndex + PAGE_SIZE;
    return orderedResults.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, orderedResults]);

  useEffect(() => {
    let ordered = null;
    if (selectedTags.length > 0) {
      ordered = orderedResults.sort((a, b) => {
        return countFilteredTags(a.Expertise) - countFilteredTags(b.Expertise) >
          0
          ? -1
          : 1;
      });
    } else {
      ordered = orderedResults.sort((a, b) => a.Title.localeCompare(b.Title));
    }

    setOrderedResults([...ordered]);
  }, [selectedTags]);

  const countFilteredTags = (tagsArray: string[]) => {
    // tagsArray length = 10 , remove selectedTags.length 2 => result X
    // tagsArray-x => count of found elements in array
    const filteredTags = tagsArray.filter((v) => !selectedTags.includes(v));

    return tagsArray.length - filteredTags.length;
  };

  const orderByChange = (event: any) => {
    let orderBy = null;
    switch (event.target.value) {
      case "A-Z":
        orderBy = orderedResults.sort((a, b) => a.Title.localeCompare(b.Title));
        break;
      case "Z-A":
        orderBy = orderedResults.sort((a, b) => b.Title.localeCompare(a.Title));
        break;
      case "Newest":
        orderBy = orderedResults.sort((a, b) => {
          return Date.parse(b.Start_Date) - Date.parse(a.Start_Date);
        });
        break;
      case "Oldest":
        orderBy = orderedResults.sort((a, b) => {
          return Date.parse(a.Start_Date) - Date.parse(b.Start_Date);
        });
        break;
      default:
        orderBy = orderedResults.sort((a, b) => a.Title.localeCompare(b.Title));
    }
    setOrderedResults([...orderBy]);
    //setCurrentPage(1);
  };

  return (
    <>
      <div className="big-padding">
        {/** -- Header Container / End */}

        {/** -- Spacer */}
        <div
          style={{
            backgroundColor: "#303030",
            paddingTop: "80px",
            opacity: "0.8",
            marginTop: "0px !important",
          }}
        ></div>
        {/** -- Spacer / End*/}

        {/** -- Page Content ================================================== */}
        <div style={{ marginTop: "40px" }} className="container">
          <div className="row">
            <div className="col-xl-3 col-lg-4">
              <div className="sidebar-container">
                {/** -- Location */}
                <div className="sidebar-widget" style={{ display: "none" }}>
                  <h3>Location</h3>
                  <div className="input-with-icon">
                    <div id="autocomplete-container">
                      <input
                        id="autocomplete-input"
                        type="text"
                        placeholder="Location"
                      />
                    </div>
                    <i className="icon-material-outline-location-on"></i>
                  </div>
                </div>

                {/** -- Keywords */}
                <div className="sidebar-widget" style={{ display: "none" }}>
                  <h3>Keywords</h3>
                  <div className="keywords-container">
                    <div className="keyword-input-container">
                      <input
                        type="text"
                        className="keyword-input"
                        placeholder="e.g. job title"
                      />
                      <button className="keyword-input-button ripple-effect">
                        <i className="icon-material-outline-add"></i>
                      </button>
                    </div>
                    <div className="keywords-list">
                      {/** -- keywords go here */}
                    </div>
                    <div className="clearfix"></div>
                  </div>
                </div>

                {/** -- Category */}
                <div className="sidebar-widget" style={{ display: "none" }}>
                  <h3>Category</h3>
                  <select
                    className="selectpicker"
                    multiple
                    data-selected-text-format="count"
                    data-size="7"
                    title="All Categories"
                  >
                    <option>Accounting and Finance</option>
                    <option>Clerical & Data Entry</option>
                    <option>Counseling</option>
                    <option>Court Administration</option>
                    <option>Human Resources</option>
                    <option>Investigative</option>
                    <option>IT and Computers</option>
                    <option>Law Enforcement</option>
                    <option>Management</option>
                    <option>Miscellaneous</option>
                    <option>Public Relations</option>
                  </select>
                </div>

                {/** -- Job Types */}
                <div className="sidebar-widget" style={{ display: "none" }}>
                  <h3>Job Type</h3>

                  <div className="switches-list">
                    <div className="switch-container">
                      <label className="switch">
                        <input type="checkbox" onChange={() => {}}></input>
                        <span className="switch-button"></span> Freelance
                      </label>
                    </div>

                    <div className="switch-container">
                      <label className="switch">
                        <input type="checkbox" onChange={() => {}} />
                        <span className="switch-button"></span> Full Time
                      </label>
                    </div>

                    <div className="switch-container">
                      <label className="switch">
                        <input type="checkbox" onChange={() => {}} />
                        <span className="switch-button"></span> Part Time
                      </label>
                    </div>

                    <div className="switch-container">
                      <label className="switch">
                        <input type="checkbox" onChange={() => {}} />
                        <span className="switch-button"></span> Internship
                      </label>
                    </div>
                    <div className="switch-container">
                      <label className="switch">
                        <input type="checkbox" onChange={() => {}} />
                        <span className="switch-button"></span> Temporary
                      </label>
                    </div>
                  </div>
                </div>

                {/** -- Salary */}
                <div className="sidebar-widget" style={{ display: "none" }}>
                  <h3>Salary</h3>
                  <div className="margin-top-55"></div>

                  {/** -- Range Slider */}
                  <input
                    className="range-slider"
                    type="text"
                    value=""
                    onChange={() => {}}
                    data-slider-currency="$"
                    data-slider-min="1500"
                    data-slider-max="15000"
                    data-slider-step="100"
                    data-slider-value="[1500,15000]"
                  />
                </div>

                {/** -- Tags */}
                <div className="sidebar-widget">
                  <h3>Tags</h3>

                  <div className="tags-container">
                    {selectedTechs?.map((tech, index) => {
                      return (
                        <div key={index} className="tag">
                          <input
                            type="checkbox"
                            id={`tag${index}`}
                            value={tech}
                            onChange={(event) => {
                              if (event.target.checked) {
                                setSelectedTags([
                                  ...selectedTags,
                                  event.target.value,
                                ]);
                              } else {
                                setSelectedTags(
                                  selectedTags.filter(
                                    (o) => o !== event.target.value
                                  )
                                );
                              }
                            }}
                          />
                          <label htmlFor={`tag${index}`}>{tech}</label>
                        </div>
                      );
                    })}
                  </div>
                  <div className="clearfix"></div>
                </div>
              </div>
            </div>
            <div className="col-xl-9 col-lg-8 content-left-offset">
              <h3 className="page-title">Search Results</h3>

              <div
                className="notify-box margin-top-15"
                style={{ minHeight: "80px" }}
              >
                <div className="switch-container" style={{ display: "none" }}>
                  <label className="switch">
                    <input type="checkbox" onChange={() => {}} />
                    <span className="switch-button"></span>
                    <span className="switch-text">
                      Turn on email alerts for this search
                    </span>
                  </label>
                </div>
                <div
                  style={{
                    display: "flex",
                    float: "right",
                    flexDirection: "row",
                    alignItems: "baseline",
                    justifyContent: "flex-start",
                  }}
                >
                  <div style={{ margin: "10px" }}>
                    <span>Sort By</span>
                  </div>
                  <div className="sort-by">
                    <select
                      className="bootstrap-select"
                      style={{ display: "inline-block !important" }}
                      onChange={orderByChange}
                    >
                      <option>A-Z</option>
                      <option>Z-A</option>
                      <option>Newest</option>
                      <option>Oldest</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="listings-container compact-list-layout margin-top-35">
                {/** -- Job Listing */}
                {currentPagedResults?.map((company: CompanyDTO, index) => {
                  return (
                    <Link
                      key={`single-job-${index}`}
                      to="../company/detail/"
                      state={{ company: company }}
                      className="job-listing"
                    >
                      {/** -- Job Listing Details */}
                      <div className="job-listing-details">
                        {/** -- Logo */}
                        {company.Logo && (
                          <div className="job-listing-company-logo">
                            <img
                              style={{ minWidth: "50px", minHeight: "50px" }}
                              src={`/assets${company.Logo}`}
                              alt=""
                            />
                          </div>
                        )}

                        {/** -- Details */}
                        <div className="job-listing-description">
                          <h3 className="job-listing-title">{company.Title}</h3>

                          {/** -- Job Listing Footer */}
                          <div className="job-listing-footer">
                            <ul key={index}>
                              <li>
                                <i className="icon-material-outline-business"></i>{" "}
                                {company.City}{" "}
                                {company.NIF && (
                                  <div
                                    className="verified-badge"
                                    title="Verified Company"
                                    data-tippy-placement="top"
                                  ></div>
                                )}
                              </li>
                              <li>
                                <i className="icon-material-outline-location-on"></i>{" "}
                                {company.District}
                              </li>
                              {company.Capital_Currency?.length > 0 && (
                                <li>
                                  <i className="icon-material-outline-business-center"></i>{" "}
                                  Capital{" "}
                                  {currencyFormat(
                                    company.Capital,
                                    company.Capital_Currency
                                  )}
                                </li>
                              )}
                              {company.Start_Date &&
                                company.End_Date.length === 0 && (
                                  <li>
                                    <i className="icon-material-outline-access-time"></i>{" "}
                                    Active Since {company.Start_Date}
                                  </li>
                                )}
                            </ul>
                          </div>
                          <div className="tags-container">
                            {company.Expertise?.map((tech, index) => {
                              return (
                                <div key={index} className="tag">
                                  <input
                                    type="checkbox"
                                    id={`tag${index}`}
                                    checked={selectedTechs?.includes(tech)}
                                    onChange={() => {}}
                                  />
                                  <label htmlFor={`tag${index}`}>{tech}</label>
                                </div>
                              );
                            })}
                          </div>
                        </div>

                        {/** -- Bookmark */}
                        <span className="bookmark-icon"></span>
                      </div>
                    </Link>
                  );
                })}
              </div>
              {orderedResults && orderedResults.length > 0 && (
                <Pagination
                  className="pagination-bar"
                  currentPage={currentPage}
                  totalCount={orderedResults.length}
                  pageSize={PAGE_SIZE}
                  onPageChange={(page: number) => setCurrentPage(page)}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Result;
