import { HttpClient } from "../interceptors/HttpClient";
import { ConnectionRequest } from "../models/ConnectionRequest";

const URL_PATHS = {
  SUBMIT_REQUEST: `${process.env.REACT_APP_SERVER_URL}/company/request`,
  NEWSLETTER_REQUEST: `${process.env.REACT_APP_SERVER_URL}/newsletter`,
};

const httpClient = new HttpClient(process.env.REACT_APP_SERVER_URL);
export default class CompanySearchService {
  private constructor() {
    console.log("Class can not be initialized");
  }

  static async submitConnectionRequest(
    connectionRequest: ConnectionRequest,
    captchaToken: string
  ): Promise<ConnectionRequest> {
    const urlPath = `${URL_PATHS.SUBMIT_REQUEST}`;
    return httpClient
      .post<ConnectionRequest>({
        url: urlPath,
        payload: connectionRequest,
        config: { headers: { Authorization: `${"CAPTCHA " + captchaToken}` } },
      })
      .then((response) => {
        return response;
      })
      .catch(async (error) => {
        throw Error(error);
      });
  }

  static async submitNewsletterRequest(email: string): Promise<string> {
    const urlPath = `${URL_PATHS.NEWSLETTER_REQUEST}/${email}`;
    return httpClient
      .post({
        url: urlPath,
      })
      .then((response: any) => {
        return response.message;
      })
      .catch(async (error) => {
        throw Error(error);
      });
  }
}
