import { isJSDocNullableType } from "typescript";
import { CompanyDTO } from "../models/CompanyDTO";

const currencyFormat = (value: any, currency: string) => {
  return (
    Number(value)
      .toFixed(2)
      .replace(/./g, function (c: any, i: any, a: any) {
        return i > 0 && c !== "." && (a.length - i) % 3 === 0 ? "," + c : c;
      }) +
    " " +
    currency
  );
};

function getResultObject(jsonStr: null | string, returnType: any) {
  if (!jsonStr) {
    return returnType instanceof Array ? [] : "";
  }
  const resultObject = JSON.parse(jsonStr);
  return resultObject;
}

function validateEmail(emailInput: string) {
  if (emailInput) {
    if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(emailInput)) {
      return true;
    }
  }

  return false;
}

export { currencyFormat, getResultObject, validateEmail };
