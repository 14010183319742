import React from "react";
import "./App.css";
import "./assets/styles/style.css";
import "./assets/styles/green.css";
import Header from "./components/header/Header";
import Intro from "./components/intro/Intro";
import Result from "./components/result/Result";
import Footer from "./components/footer/Footer";
import Privacy from "./components/privacy/Privacy";
import CompanyDetail from "./components/company/detail/CompanyDetail";
import {
  Route,
  BrowserRouter as Router,
  Routes,
  useNavigate,
} from "react-router-dom";
import { useState } from "react";
import { useCallback } from "react";
import { CompanyDTO } from "./models/CompanyDTO";
import { searchResultStore } from "./state/useStore";
import CookieConsent from "react-cookie-consent";

const App = () => {
  const navigate = useNavigate();

  const { setResults, setSearchLocation, setTags, results, searchTags } =
    searchResultStore();
  const [searchResults, setSearchResults] = useState<CompanyDTO[]>(
    results || []
  );
  const [selectedTechs, setSelectedTechs] = useState<string[]>(
    searchTags || []
  );
  const searchResultCallback = useCallback(
    (result: CompanyDTO[], techs: string[], location: string) => {
      if (result) {
        setSearchResults(result);
        setSelectedTechs(techs);
        setResults(result);
        setSearchLocation(location);
        setTags(techs);
        localStorage.setItem("companyResult", JSON.stringify(result));
        localStorage.setItem("searchTags", JSON.stringify(techs));
        localStorage.setItem("searchLocation", JSON.stringify(location));
        navigate("/company/list");
      }
    },
    []
  );

  return (
    <div id="wrapper" className="wrapper-with-transparent-header">
      <Header />
      <Routes>
        <Route
          path="/"
          element={<Intro searchResults={searchResultCallback} />}
        />
        <Route
          path="/company/list"
          element={
            <Result
              searchResults={searchResults}
              selectedTechs={selectedTechs}
            />
          }
        />
        <Route path="/company/detail" element={<CompanyDetail />} />
        <Route path="/privacy" element={<Privacy />} />
      </Routes>
      <Footer />
      <CookieConsent
        location="bottom"
        cookieName="myAwesomeCookieName3"
        expires={999}
        overlay
        enableDeclineButton
      >
        This website uses cookies to enhance the user experience.
      </CookieConsent>
    </div>
  );
};

export default App;
