import React, { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { currencyFormat } from "../../../helpers/Utils";
import { CompanyDTO } from "../../../models/CompanyDTO";
import CountryCitySearchService from "../../../services/CountryCitySearchService";
import BusinessIcon from "@mui/icons-material/Business";
import { Chip, Dialog, IconButton, Snackbar, styled } from "@mui/material";
import SendMailDialog from "../../dialog/SendMailDialog";
import CloseIcon from "@mui/icons-material/Close";

interface CompanyDetailProp {
  company?: CompanyDTO;
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const CompanyDetail = () => {
  const location = useLocation();
  const state = location.state as CompanyDetailProp; // Type Casting, then you can get the params passed via router
  const { company } = state;
  const [countryCode, setCountryCode] = useState("");
  const [showMore, setShowMore] = useState(false);
  const [connectImages, setConnectImages] = useState([
    "/assets/plugin.png",
    "/assets/plugedin.png",
  ]);
  const [currentConnectImage, setCurrentConnectImage] = useState(0);
  const [sendMailDialogOpen, setSendMailDialogOpen] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");

  const getLocation = async (location: string) => {
    if (location == "") {
      return;
    }
    const response = await CountryCitySearchService.filterCountryCityByText(
      location
    );

    if (response?.Countries?.length > 0) {
      setCountryCode(response?.Countries[0].code.toLowerCase());
      return;
    }

    if (response?.Cities?.length > 0) {
      setCountryCode(response?.Cities[0].country.toLowerCase());
      return;
    }
  };

  const openInNewTab = (url: string) => {
    let urlTarget = url;
    if (url.indexOf("http") < 0) {
      urlTarget = `http://${url}`;
    }
    window.open(urlTarget, "_blank", "noopener,noreferrer");
  };

  const handleCloseSnackBar = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackBar(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const actionSnackBar = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseSnackBar}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  useEffect(() => {
    if (company && !countryCode) {
      getLocation(company.Country);
    }
  }, [countryCode]);

  useEffect(() => {
    const interval = setInterval(() => {
      const counter = currentConnectImage + 1;

      setCurrentConnectImage(counter % 2);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  });

  const getAllExpertise = () => {
    return company?.Expertise?.map((tech, index) => {
      return (
        <div key={index} className="tag">
          <Chip label={tech} />
        </div>
      );
    });
  };
  const getExpertise = () => {
    let expertise;
    if ((company?.Expertise && company?.Expertise?.length < 20) || showMore) {
      return getAllExpertise();
    }
    if (company?.Expertise && company?.Expertise?.length > 20) {
      if (!showMore) {
        expertise = company?.Expertise?.slice(0, 20).map((tech, index) => {
          return (
            <div key={index} className="tag">
              <Chip label={tech} />
            </div>
          );
        });

        return expertise;
      }
    }
  };

  const dialogClosedCallback = useCallback((closed: boolean) => {
    if (closed) {
      setSendMailDialogOpen(!closed);
    }
  }, []);

  const isSuccessCallback = useCallback((isSuccess: boolean) => {
    setOpenSnackBar(true);
    if (isSuccess) {
      setSnackBarMessage(
        "Message has been sent succesfully, our Team will get in touch with you soon."
      );
    } else {
      setSnackBarMessage("An error occured, please try again");
    }
  }, []);

  return (
    <>
      {/** -- Spacer */}
      <div
        style={{
          backgroundColor: "#303030",
          paddingTop: "80px",
          opacity: "0.8",
          marginTop: "0px !important",
        }}
      ></div>
      {/** -- Spacer / End*/}
      <div
        className="single-page-header freelancer-header"
        data-background-image="images/single-freelancer.jpg"
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="single-page-header-inner">
                <div>
                  <div>
                    {company?.Logo ? (
                      <img src={`/assets${company?.Logo}`} alt="" />
                    ) : (
                      company?.Title
                    )}
                  </div>
                  <div className="header-details">
                    <ul>
                      <li style={{ display: "none" }}>
                        <div className="star-rating" data-rating="5.0"></div>
                      </li>
                      <li>
                        <img
                          className="flag"
                          src={`/assets/flags/${countryCode}.svg`}
                          title={company?.Country}
                        />
                        {/* */}
                        {company?.District}
                      </li>
                      {company?.NIF && (
                        <>
                          <li>
                            <div className="verified-badge-with-title">
                              Verified VAT
                            </div>
                          </li>
                          <li>
                            <div>{company?.NIF}</div>
                          </li>
                        </>
                      )}
                      {company?.Capital_Currency &&
                        company?.Capital_Currency?.length > 0 && (
                          <li>
                            <i className="icon-material-outline-business-center"></i>{" "}
                            Capital{" "}
                            {currencyFormat(
                              company?.Capital,
                              company?.Capital_Currency
                            )}
                          </li>
                        )}
                      {company?.Start_Date && company.End_Date.length === 0 && (
                        <li>
                          <i className="icon-material-outline-access-time"></i>{" "}
                          Active Since {company.Start_Date}
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*-- Page Content ================================================== */}
      <div className="container">
        <div className="row">
          {/*-- Content */}
          <div className="col-xl-8 col-lg-8 content-right-offset">
            {/*-- Page Content */}
            <div className="single-page-section">
              <h3 className="margin-bottom-25">{company?.Title}</h3>
              <p style={{ marginBottom: "40px" }}>{company?.Activity}</p>

              <div
                style={{ display: "flex", flexDirection: "row", gap: "4px" }}
              >
                <div>
                  <BusinessIcon />
                </div>
                <div>{company?.Address}</div>
              </div>

              {company?.Website && (
                <div
                  style={{ display: "flex", flexDirection: "row", gap: "4px" }}
                >
                  <div>
                    <i className="icon-material-outline-language"></i>
                  </div>
                  <div>
                    <a
                      href="#"
                      onClick={(e) => openInNewTab(company?.Website)}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {company?.Website}
                    </a>
                  </div>
                </div>
              )}
            </div>

            {/*-- Boxed List */}
            <div
              style={{ display: "none" }}
              className="boxed-list margin-bottom-60"
            >
              <div className="boxed-list-headline">
                <h3>
                  <i className="icon-material-outline-thumb-up"></i> Work
                  History and Feedback
                </h3>
              </div>
              <ul className="boxed-list-ul">
                <li>
                  <div className="boxed-list-item">
                    {/*-- Content */}
                    <div className="item-content">
                      <h4>
                        Web, Database and API Developer{/* */}
                        <span>Rated as Freelancer</span>
                      </h4>
                      <div className="item-details margin-top-10">
                        <div className="star-rating" data-rating="5.0"></div>
                        <div className="detail-item">
                          <i className="icon-material-outline-date-range"></i>
                          {/* */}
                          August 2019
                        </div>
                      </div>
                      <div className="item-description">
                        <p>
                          Excellent programmer - fully carried out my project in
                          a very professional manner.{/* */}
                        </p>
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="boxed-list-item">
                    {/*-- Content */}
                    <div className="item-content">
                      <h4>
                        WordPress Theme Installation{/* */}
                        <span>Rated as Freelancer</span>
                      </h4>
                      <div className="item-details margin-top-10">
                        <div className="star-rating" data-rating="5.0"></div>
                        <div className="detail-item">
                          <i className="icon-material-outline-date-range"></i>
                          {/* */}
                          June 2019
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="boxed-list-item">
                    {/*-- Content */}
                    <div className="item-content">
                      <h4>
                        Fix Python Selenium Code <span>Rated as Employer</span>
                      </h4>
                      <div className="item-details margin-top-10">
                        <div className="star-rating" data-rating="5.0"></div>
                        <div className="detail-item">
                          <i className="icon-material-outline-date-range"></i>
                          {/* */}
                          May 2019
                        </div>
                      </div>
                      <div className="item-description">
                        <p>
                          I was extremely impressed with the quality of work AND
                          how quickly he got it done. He then offered to help
                          with another side part of the project that we didnt
                          even think about originally.
                        </p>
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="boxed-list-item">
                    {/*-- Content */}
                    <div className="item-content">
                      <h4>
                        PHP Core Website Fixes <span>Rated as Freelancer</span>
                      </h4>
                      <div className="item-details margin-top-10">
                        <div className="star-rating" data-rating="5.0"></div>
                        <div className="detail-item">
                          <i className="icon-material-outline-date-range"></i>
                          {/* */}
                          May 2019
                        </div>
                      </div>
                      <div className="item-description">
                        <p>
                          Awesome work, definitely will rehire. Poject was
                          completed not only with the requirements, but on time,
                          within our small budget.
                        </p>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>

              {/*-- Pagination */}
              <div className="clearfix"></div>
              <div className="pagination-container margin-top-40 margin-bottom-10">
                <nav className="pagination">
                  <ul>
                    <li>
                      <a href="#" className="ripple-effect current-page">
                        1
                      </a>
                    </li>
                    <li>
                      <a href="#" className="ripple-effect">
                        2
                      </a>
                    </li>
                    <li className="pagination-arrow">
                      <a href="#" className="ripple-effect">
                        <i className="icon-material-outline-keyboard-arrow-right"></i>
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
              <div className="clearfix"></div>
              {/*-- Pagination / End */}
            </div>
            {/*-- Boxed List / End */}

            {/*-- Boxed List */}
            <div
              style={{ display: "none" }}
              className="boxed-list margin-bottom-60"
            >
              <div className="boxed-list-headline">
                <h3>
                  <i className="icon-material-outline-business"></i> Employment
                  History
                </h3>
              </div>
              <ul className="boxed-list-ul">
                <li>
                  <div className="boxed-list-item">
                    {/*-- Avatar */}
                    <div className="item-image">
                      <img src="images/browse-companies-03.png" alt="" />
                    </div>

                    {/*-- Content */}
                    <div className="item-content">
                      <h4>Development Team Leader</h4>
                      <div className="item-details margin-top-7">
                        <div className="detail-item">
                          <a href="#">
                            <i className="icon-material-outline-business"></i>
                            {/* */}
                            Acodia
                          </a>
                        </div>
                        <div className="detail-item">
                          <i className="icon-material-outline-date-range"></i>
                          {/* */}
                          May 2019 - Present
                        </div>
                      </div>
                      <div className="item-description">
                        <p>
                          Focus the team on the tasks at hand or the internal
                          and external customer requirements.
                        </p>
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="boxed-list-item">
                    {/*-- Avatar */}
                    <div className="item-image">
                      <img src="images/browse-companies-04.png" alt="" />
                    </div>

                    {/*-- Content */}
                    <div className="item-content">
                      <h4>
                        <a href="#">Lead UX/UI Designer</a>
                      </h4>
                      <div className="item-details margin-top-7">
                        <div className="detail-item">
                          <a href="#">
                            <i className="icon-material-outline-business"></i>
                            {/* */}
                            Acorta
                          </a>
                        </div>
                        <div className="detail-item">
                          <i className="icon-material-outline-date-range"></i>
                          {/* */}
                          April 2014 - May 2019
                        </div>
                      </div>
                      <div className="item-description">
                        <p>
                          I designed and implemented 10+ custom web-based CRMs,
                          workflow systems, payment solutions and mobile apps.
                        </p>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            {/*-- Boxed List / End */}
          </div>

          {/*-- Sidebar */}
          <div className="col-xl-4 col-lg-4">
            <div className="sidebar-container">
              {/*-- Profile Overview */}
              <div style={{ display: "none" }} className="profile-overview">
                <div className="overview-item">
                  <strong>$35</strong>
                  <span>Hourly Rate</span>
                </div>
                <div className="overview-item">
                  <strong>53</strong>
                  <span>Jobs Done</span>
                </div>
                <div className="overview-item">
                  <strong>22</strong>
                  <span>Rehired</span>
                </div>
              </div>

              {/*-- Button */}
              <div
                id="connect-dialog"
                style={{ cursor: "pointer" }}
                onClick={() => setSendMailDialogOpen(!sendMailDialogOpen)}
                className="apply-now-button popup-with-zoom-anim margin-bottom-50"
              >
                <span style={{ fontWeight: "900" }}>Connect{/* */}</span>

                <img
                  id="plugin-icon"
                  style={{
                    width: "32px",
                    height: "32px",
                    marginLeft: "10px",
                    filter: "brightness(0) invert(1)",
                  }}
                  src={connectImages[currentConnectImage]}
                  alt=""
                />
              </div>

              <div
                style={{
                  marginTop: "40px",
                  display: "flex",
                  flexDirection: "column",
                }}
                className="margin-bottom-60"
              >
                {/** -- Tags */}
                <div className="boxed-list-headline">
                  <h3>
                    <i className="icon-material-outline-business"></i> Expertise
                  </h3>
                </div>
                <div className="clearfix"></div>
                <div style={{ marginTop: "40px" }} className="tags-container">
                  {getExpertise()}
                </div>
                {company?.Expertise && company?.Expertise.length > 20 && (
                  <button onClick={() => setShowMore(!showMore)}>
                    Show {showMore ? "Less" : "More"}
                  </button>
                )}
                <div className="clearfix"></div>
              </div>

              {/*-- Freelancer Indicators */}
              <div style={{ display: "none" }} className="sidebar-widget">
                <div className="freelancer-indicators">
                  {/*-- Indicator */}
                  <div className="indicator">
                    <strong>88%</strong>
                    <div
                      className="indicator-bar"
                      data-indicator-percentage="88"
                    >
                      <span></span>
                    </div>
                    <span>Job Success</span>
                  </div>

                  {/*-- Indicator */}
                  <div className="indicator">
                    <strong>100%</strong>
                    <div
                      className="indicator-bar"
                      data-indicator-percentage="100"
                    >
                      <span></span>
                    </div>
                    <span>Recommendation</span>
                  </div>

                  {/*-- Indicator */}
                  <div className="indicator">
                    <strong>90%</strong>
                    <div
                      className="indicator-bar"
                      data-indicator-percentage="90"
                    >
                      <span></span>
                    </div>
                    <span>On Time</span>
                  </div>

                  {/*-- Indicator */}
                  <div className="indicator">
                    <strong>80%</strong>
                    <div
                      className="indicator-bar"
                      data-indicator-percentage="80"
                    >
                      <span></span>
                    </div>
                    <span>On Budget</span>
                  </div>
                </div>
              </div>

              {/*-- Widget */}
              <div style={{ display: "none" }} className="sidebar-widget">
                <h3>Social Profiles</h3>
                <div className="freelancer-socials margin-top-25">
                  <ul>
                    <li>
                      <a href="#" title="Dribbble" data-tippy-placement="top">
                        <i className="icon-brand-dribbble"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#" title="Twitter" data-tippy-placement="top">
                        <i className="icon-brand-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#" title="Behance" data-tippy-placement="top">
                        <i className="icon-brand-behance"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#" title="GitHub" data-tippy-placement="top">
                        <i className="icon-brand-github"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              {/*-- Widget */}
              <div style={{ display: "none" }} className="sidebar-widget">
                <h3>Attachments</h3>
                <div className="attachments-container">
                  <a href="#" className="attachment-box ripple-effect">
                    <span>Cover Letter</span>
                    <i>PDF</i>
                  </a>
                  <a href="#" className="attachment-box ripple-effect">
                    <span>Contract</span>
                    <i>DOCX</i>
                  </a>
                </div>
              </div>

              {/*-- Sidebar Widget */}
              <div style={{ display: "none" }} className="sidebar-widget">
                <h3>Bookmark or Share</h3>

                {/*-- Bookmark Button */}
                <button className="bookmark-button margin-bottom-25">
                  <span className="bookmark-icon"></span>
                  <span className="bookmark-text">Bookmark</span>
                  <span className="bookmarked-text">Bookmarked</span>
                </button>

                {/*-- Copy URL */}
                <div className="copy-url">
                  <input
                    id="copy-url"
                    type="text"
                    value=""
                    className="with-border"
                    onChange={() => {}}
                  />
                  <button
                    className="copy-url-button ripple-effect"
                    data-clipboard-target="#copy-url"
                    title="Copy to Clipboard"
                    data-tippy-placement="top"
                  >
                    <i className="icon-material-outline-file-copy"></i>
                  </button>
                </div>

                {/*-- Share Buttons */}
                <div className="share-buttons margin-top-25">
                  <div className="share-buttons-trigger">
                    <i className="icon-feather-share-2"></i>
                  </div>
                  <div className="share-buttons-content">
                    <span>
                      Interesting? <strong>Share It!</strong>
                    </span>
                    <ul className="share-buttons-icons">
                      <li>
                        <a
                          href="#"
                          data-button-color="#3b5998"
                          title="Share on Facebook"
                          data-tippy-placement="top"
                        >
                          <i className="icon-brand-facebook-f"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          data-button-color="#1da1f2"
                          title="Share on Twitter"
                          data-tippy-placement="top"
                        >
                          <i className="icon-brand-twitter"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          data-button-color="#dd4b39"
                          title="Share on Google Plus"
                          data-tippy-placement="top"
                        >
                          <i className="icon-brand-google-plus-g"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          data-button-color="#0077b5"
                          title="Share on LinkedIn"
                          data-tippy-placement="top"
                        >
                          <i className="icon-brand-linkedin-in"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SendMailDialog
        openDialog={sendMailDialogOpen}
        dialogClosed={dialogClosedCallback}
        selectedCompany={company}
        isSuccess={isSuccessCallback}
      />
      <Snackbar
        open={openSnackBar}
        autoHideDuration={10000}
        onClose={handleCloseSnackBar}
        message={snackBarMessage}
        action={actionSnackBar}
      ></Snackbar>
    </>
  );
};

export default CompanyDetail;
