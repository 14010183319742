import { DOTS, usePagination } from "../../helpers/usePagination";
import classnames from "classnames";
import "./pagination.css";
interface PaginationProps {
  onPageChange: (page: any) => void;
  totalCount: number;
  siblingCount?: number;
  currentPage: number;
  pageSize: number;
  className: any;
}

const Pagination = (props: PaginationProps) => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
    className,
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  // If there are less than 2 times in pagination range we shall not render the component
  if (currentPage === 0 || (paginationRange && paginationRange.length < 2)) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  let lastPage;
  if (paginationRange) paginationRange[paginationRange.length - 1];

  return (
    <>
      {/** -- Pagination */}

      <div className="clearfix"></div>
      <div className="row">
        <div className="col-md-12">
          {/** -- Pagination */}
          <div className="pagination-container margin-top-60 margin-bottom-60">
            <nav className="pagination">
              <ul
                className={classnames("pagination-container", {
                  [className]: className,
                })}
              >
                <li
                  className={classnames("pagination-item", {
                    disabled: currentPage === 1,
                  })}
                  onClick={onPrevious}
                >
                  <div className="arrow left" />
                </li>
                {paginationRange?.map((pageNumber) => {
                  if (pageNumber === DOTS) {
                    return (
                      <li key={`dots`} className="pagination-item dots">
                        &#8230;
                      </li>
                    );
                  }

                  return (
                    <li
                      className={classnames("pagination-item", {
                        selected: pageNumber === currentPage,
                      })}
                      onClick={() => onPageChange(pageNumber)}
                      key={`page-item-${pageNumber}`}
                    >
                      {pageNumber}
                    </li>
                  );
                })}
                <li
                  className={classnames("pagination-item", {
                    disabled: currentPage === lastPage,
                  })}
                  onClick={onNext}
                >
                  <div className="arrow right" />
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      {/** -- Pagination / End */}
    </>
  );
};

Pagination.defaultProps = {
  siblingCounts: 1,
};

export default Pagination;
