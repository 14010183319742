import React, { useEffect, createRef, useState } from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import SendIcon from "@mui/icons-material/Send";
import InfoIcon from "@mui/icons-material/Info";
import "./sendMailDialog.css";
import { ConnectionRequest } from "../../models/ConnectionRequest";
import CompanyConnectionService from "../../services/CompanyConnectionService";
import ReCAPTCHA from "react-google-recaptcha";

import {
  FormControl,
  InputLabel,
  NativeSelect,
  TextField,
  Tooltip,
} from "@mui/material";
import { validateEmail } from "../../helpers/Utils";
import { CompanyDTO } from "../../models/CompanyDTO";
import { useRef } from "react";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BUSINESS_MODEL_INFO: { [key: number]: string } = {
  10: "A company expands its in-house staff by outsourcing IT Specialists temporarly.",
  20: "A client hires a development team to do a specific scope of work. The outsourced team doesn't work directly with the client's in-house team but usually reports to their PM,CTO or the client themselves",
  30: "A client fully delegates software development to an outsourcing company from planning to release",
};

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

interface SendMailDialogProps {
  openDialog: boolean;
  dialogClosed: (closed: boolean) => void;
  selectedCompany?: CompanyDTO;
  isSuccess?: (sent: boolean) => void;
}

const SendMailDialog = (props: SendMailDialogProps) => {
  const { openDialog, dialogClosed, selectedCompany, isSuccess } = props;
  const [open, setOpen] = React.useState(false);
  const [projectScopeValue, setProjectScopeValue] = useState("");
  const [projectDetails, setProjectDetails] = useState("");
  const [tooltipIsOpen, setTooltipIsOpen] = useState(false);
  const [toolTipText, setToolTipText] = useState("");

  const inputNameRef = createRef<HTMLInputElement>();
  const [inputNameError, setInputNameError] = useState(false);
  const emailAddressRef = createRef<HTMLInputElement>();
  const [emailAddressError, setEmailAddressError] = useState(false);
  const contactInfoRef = createRef<HTMLInputElement>();
  const [contactInfoError, setContactInfoError] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [captchaToken, setCaptchaToken] = useState("");
  const captchaRef = useRef<ReCAPTCHA>(null);

  useEffect(() => {
    setOpen(openDialog);
  }, [openDialog]);

  const validateForm = () => {
    let valid = true;
    if (inputNameRef.current?.value.length === 0) {
      setInputNameError(true);
      valid = false;
    } else {
      setInputNameError(false);
    }

    if (contactInfoRef.current?.value.length === 0) {
      setContactInfoError(true);
      valid = false;
    } else {
      setContactInfoError(false);
    }

    if (
      emailAddressRef.current?.value.length === 0 ||
      !validateEmail(emailAddressRef?.current!.value)
    ) {
      setEmailAddressError(true);
      valid = false;
    } else {
      setEmailAddressError(false);
    }
    return valid;
  };
  const handleClose = () => {
    setOpen(false);
    dialogClosed(true);
  };

  const businessModelOnChangeHandler = (event: any) => {
    setProjectScopeValue(event.target.value);
    setToolTipText(BUSINESS_MODEL_INFO[event.target.value]);
  };

  const handleOnchangeCaptcha = (value: any) => {
    if (value) {
      setCaptchaToken(value);
      setIsDisabled(!isDisabled && !validateForm());
    }
  };

  const submitForm = async () => {
    if (!validateForm()) {
      return;
    }
    const sendInterest = {
      companyId: selectedCompany?.ID,
      contactName: inputNameRef.current?.value,
      emailAddress: emailAddressRef.current?.value,
      phoneContactInfo: contactInfoRef.current?.value,
      projectScope: projectScopeValue,
      projectDetails: projectDetails,
    } as ConnectionRequest;

    await CompanyConnectionService.submitConnectionRequest(
      sendInterest,
      captchaToken
    ).then((res) => {
      if (res) {
        if (isSuccess) {
          isSuccess(true);
          const token = captchaRef.current?.getValue();
          captchaRef.current?.reset();
        }

        handleClose();
      }
    });
  };

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="sm"
        fullWidth
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Business Request
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <div className="popup-tab-content" id="tab">
            <div className="input-with-icon-left">
              <i
                className={`icon-material-outline-account-circle ${
                  inputNameError ? "input-name-icon-error" : ""
                }`}
              ></i>
              <input
                type="text"
                className={`input-text with-border ${
                  inputNameError ? "input-name-error" : ""
                }`}
                name="name"
                id="name"
                placeholder="First and Last Name"
                ref={inputNameRef}
                required
              />
            </div>

            <div className="input-with-icon-left">
              <i
                className={`icon-material-baseline-mail-outline ${
                  emailAddressError ? "input-name-icon-error" : ""
                }`}
              ></i>
              <input
                type="text"
                className={`input-text with-border ${
                  emailAddressError ? "input-name-error" : ""
                }`}
                name="emailaddress"
                id="emailaddress"
                placeholder="Email Address"
                required
                ref={emailAddressRef}
              />
            </div>
            <div className="input-with-icon-left">
              <i
                className={`icon-feather-phone ${
                  contactInfoError ? "input-name-icon-error" : ""
                }`}
              ></i>
              <input
                type="text"
                className={`input-text with-border ${
                  contactInfoError ? "input-name-error" : ""
                }`}
                name="contact"
                id="contact"
                placeholder="Contact"
                required
                ref={contactInfoRef}
              />
            </div>
            <div className="input-with-icon-left">
              <i className="icon-material-outline-language"></i>
              <input
                type="text"
                className="input-text with-border"
                name="webSite"
                id="webSite"
                placeholder="Company Web Site"
              />
            </div>
            <div className="margin-bottom-25">
              <Tooltip
                open={tooltipIsOpen}
                onOpen={() => setTooltipIsOpen(!tooltipIsOpen)}
                onClose={() => setTooltipIsOpen(false)}
                disableHoverListener
                disableFocusListener
                disableTouchListener
                title={toolTipText}
                PopperProps={{
                  sx: {
                    marginRight: "32px !important",
                  },
                }}
              >
                <FormControl fullWidth>
                  <InputLabel
                    style={{ top: "-10" }}
                    variant="standard"
                    htmlFor="uncontrolled-native"
                  >
                    Business/Consultancy Model{" "}
                    <InfoIcon
                      onClick={() => setTooltipIsOpen(!tooltipIsOpen)}
                    />
                  </InputLabel>
                  <NativeSelect
                    defaultValue=""
                    inputProps={{
                      name: "businessModel",
                      id: "uncontrolled-native",
                    }}
                    onChange={businessModelOnChangeHandler}
                  >
                    <option value={0}></option>
                    <option value={10}>Staff Augmentation</option>
                    <option value={20}>Managed/Dedicated Team</option>
                    <option value={30}>Project Based</option>
                  </NativeSelect>
                </FormControl>
              </Tooltip>
            </div>

            <TextField
              id="outlined-multiline-static"
              label="Business/Project Details"
              multiline
              rows={2}
              fullWidth
              value={projectDetails}
              onChange={(e) => setProjectDetails(e.target.value)}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_SITE_KEY || "1"}
            ref={captchaRef}
            style={{ marginRight: "10px" }}
            lang="en"
            onChange={handleOnchangeCaptcha}
          />
          <Button
            variant="contained"
            style={{
              backgroundColor: "#28b661",
            }}
            onClick={submitForm}
            disabled={isDisabled}
            endIcon={<SendIcon />}
          >
            Send
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};

export default SendMailDialog;
