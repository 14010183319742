import create from "zustand";
import { getResultObject } from "../helpers/Utils";
import { CompanyDTO } from "../models/CompanyDTO";

interface CompanySearchResultState {
  results: CompanyDTO[];
  searchTags: string[];
  searchLocation: string;
  setResults: (companies: CompanyDTO[]) => void;
  setSearchLocation: (location: string) => void;
  setTags: (tags: string[]) => void;
}

const searchResultStore = create<CompanySearchResultState>((set) => ({
  // initial state
  results: localStorage.getItem("companyResult")
    ? JSON.parse(localStorage.getItem("companyResult")!)
    : [],
  searchTags: localStorage.getItem("searchTags")
    ? JSON.parse(localStorage.getItem("searchTags")!)
    : [],
  searchLocation: localStorage.getItem("searchLocation")
    ? JSON.parse(localStorage.getItem("searchLocation")!)
    : "",
  setResults: (companies: CompanyDTO[]) =>
    set((state) => ({ results: companies })),
  setSearchLocation: (location: string) =>
    set(() => ({ searchLocation: location })),
  setTags: (tags: string[]) => set(() => ({ searchTags: tags })),
}));

export { searchResultStore };
