import { HttpClient } from "../interceptors/HttpClient";
import { CountryCityResponseDTO } from "../models/CountryCityResponseDTO";

const URL_PATHS = {
  GET_COUNTRY_CITY: `${process.env.REACT_APP_SERVER_URL}/country/city`,
};

const httpClient = new HttpClient(process.env.REACT_APP_SERVER_URL);

export default class CountryCitySearchService {
  private constructor() {
    console.log("Class can not be initialized");
  }

  static async filterCountryCityByText(
    text: string
  ): Promise<CountryCityResponseDTO> {
    const urlPath = `${URL_PATHS.GET_COUNTRY_CITY}/${text}`;
    return httpClient
      .get<CountryCityResponseDTO>({ url: urlPath })
      .then((response) => {
        return response;
      })
      .catch(async (error) => {
        throw Error(error);
      });
  }
}
